/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
// @import url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');

$primary-color: #000;


.heading,
.alert-title {
    font-family: 'Raleway', sans-serif;
}

.primary__input {
    border: 1.5px solid #C3E5EE;
    border-radius: 5px;
}
.text-danger{
    color: #C62D71;
}
.cursor{
    cursor: pointer;
}
.disabled-elm{
    cursor: not-allowed;
}
.alert-radio-group::-webkit-scrollbar {
    width: 0.8em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.5px solid slategrey;
}
.primary__button {
    // background-image: linear-gradient(to right, #E64154, #C62D71);
    // --background: linear-gradient(to right, #E64154, #C62D71);
    background: #e01c7a !important;
    --background: #e01c7a !important;
    width: 100%;
    padding: 0.9rem;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    min-height: 44px;
}
.primary__button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}
.primary__border__button {
    
    background-color: transparent;
    width: 100%;
    // padding: 0.9rem;
    color: #E64154;
    border-radius: 5px;
    min-height: 44px;
    font-size: 15px;
    &.bordered {
        border: 1.5px solid #E64154;
    }
    &.gray-button {
        border: 1.5px solid #8B8E8F;
        // color: #8f8383;
    }
}
.text-primary{
    color: #076680;
}

.link:hover {
    border-bottom: 1px solid #E64154;
    cursor: pointer;
}
.text-success {
    color: green;
}
// ion-menu {
//     max-width: 25%!important;
// }

.hide-on-small {
    display: none !important;
    @media screen and (min-width: 768px) {
        display: block !important;
    }
}
.hide-on-medium {
    display: block !important;
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

@media only screen and (min-width: 361px) and (max-width: 580px) {
    .mobile-button {
        margin-left: -12%;
    }
}